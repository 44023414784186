<template>
  <div
    class="ir is-loading"
    :class="customWrapperClass"
    :style="((inlineIr && aspectRatio) ? `padding-bottom: ${(100 / aspectRatio)}%` : paddingBottom)"
  >
    <img
      v-if="image"
      :alt="imageAlt"
      :class="imageClass"
      :data-src="imageUrl"
      :data-widths="imageWidths"
      class="lazyload"
      data-sizes="auto"
      tabindex="-1"
    >
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';

export default {
  name: 'ResponsiveImage',
  props: {
    image: {
      default: null,
      required: false,
      type: String,
    },
    imageAlt: {
      default: '',
      required: false,
      type: String,
    },
    imageClass: {
      default: null,
      required: false,
      type: String,
    },
    inlineIr: {
      default: null,
      required: false,
      type: Boolean,
    },
    modifier: {
      default: null,
      required: false,
      type: String,
    },
    paddingBottom: {
      default: null,
      required: false,
      type: String,
    },
    wrapperClass: {
      default: null,
      required: false,
      type: String,
    },
  },
  setup(props) {
    const imageWidths = '[180,360,540,720,900,1080,1296,1512,1728,1944,2160,2376,2592,2808,3024]';
    const aspectRatio = ref(1);

    onMounted(() => {
      function getImageData() {
        const $image = new Image();
        let imageSrc;

        if (props.image?.includes('cdn.shopify.com')) {
          const returnImage = __.getSizedImageUrl(props.image, '1x1').replace('_1x1.', '_{width}x.');

          imageSrc = returnImage;
        }
        imageSrc = props.image;
        $image.src = imageSrc;
        $image.onload = (img) => {
          const height = img?.target?.naturalWidth;
          const width = img?.target?.naturalHeight;

          if (width && height) {
            aspectRatio.value = height / width;
          }
        };
        $image.onerror = (err) => {
          console.error(err);

          aspectRatio.value = 1;
        };
      }

      if (props.inlineIr) {
        getImageData();
      }
    });

    const customWrapperClass = computed(() => {
      let append = '';
      if (props.modifier) {
        append += `ir--${props.modifier}`;
      }
      if (props.wrapperClass) {
        append += ` ${props.wrapperClass}`;
      }
      return append;
    });

    const imageUrl = computed(() => {
      if (props.image?.includes('cdn.shopify.com')) {
        const returnImage = __.getSizedImageUrl(props.image, '1x1').replace('_1x1.', '_{width}x.');

        return returnImage;
      }
      // External image
      const image = props.image.replace(/(\.jpg)(\?.*)?$/, '_grande$1$2');
      return image;
    });

    return {
      aspectRatio,
      customWrapperClass,
      imageWidths,
      imageUrl,
    };
  },
};
</script>
